import { defineStore } from 'pinia'
import { useApi } from '~/composables/useApi'

export const useFeaturedStore = defineStore('featured', {
  state: () => ({
    venues: {
      loading: false,
      total: 0,
      data: [],
    },

    nearby: {
      total: 0,
      data: [],
      heading: '',
    },

    content: {
      loading: false,
      total: 0,
      data: [],
    },

    homePagePhotos: [],
  }),

  actions: {
    async loadFeaturedVenues () {
      this.venues.loading = true
      try {
        const resp = await useApi('featured_venues')
        this.venues.data = resp.response.featured_venues.venues
        this.venues.total = resp.response.featured_venues.total_records
        this.nearby.resp = resp.response.nearby_venues.venues
        this.nearby.total = resp.response.nearby_venues.total_records
        this.nearby.heading = resp.response.nearby_venues.heading
        this.homePagePhotos = resp.response.home_page_photos
      } finally {
        this.venues.loading = false
      }
    },

    async loadFeaturedContent () {
      this.content.loading = true
      try {
        const resp = await useApi('featured_content')
        this.content.data = resp.response.posts
        this.content.total = resp.response.total_records
      } finally {
        this.content.loading = false
      }
    },
  },
})
